import Splide from '@splidejs/splide';

// Splide carousel
const splide = new Splide(".splide", {
    type: "loop",
    perPage: 3,
    arrows: true,
    pagination: false,
    autoplay: false,
    speed: 1500,
    pauseOnHover: true,
    updateOnMove: true,
    keyboard: "global",
    breakpoints: {
        900: {
            perPage: 2,
        },
        700: {
            perPage: 1,
            arrows: false,
            pagination: true,
        }
    }
});

setTimeout(function () {
    splide.mount();
}, 800)
